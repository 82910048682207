import React, { Component } from "react";

import Navbar from "../Navbar/Navbar";


class Homepage extends Component {

  render() {
    return (
      <div style={{
          textDecoration: "none",
          position: "relative",
          minHeight: '100vh',
          margin: 0,
          padding: 0,
          border: 0,
          alignItems: "center",
          background: `#FCEE23`,
      }}>
        <Navbar absolute color="dark" brand="Mbuzi24" fixed />
        <div style={{ height: '50px' }}></div>
        <div >
        </div>
            <div style={{
              minHeight: "30vh",
              color: "#1C1E1E",
              background: "#FFFFFF",
              border: 0,
              borderRadius: "36px 36px 0px 36px",
              fontWeight: "400",
              fontSize: "32px",
              width: "100%",
              position: "relative",
              alignItems: "center",
              textAlign: "center",
              letterSpacing: "0px",
              padding: "10px",
              paddingTop: '15px',
              paddingBottom: '15px',
              opacity: "71%",
            }}>
              <div style={{

              }}>
               Loading ....
              </div>
            </div>
      </div>
    );
  }
}


export default Homepage;
