const initialState = {
	scoring: null
};

const createDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'INIT_STATE':
            return {
                ...initialState
            };  		
		default:
			return state;
	}
};
export default createDataReducer;