import React, { lazy, Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { withFirebase } from "../../firebase";

import AppBar from "@material-ui/core/AppBar";
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";

import Menu from "@material-ui/icons/Menu";
import navbarStyle from "./navbarStyle";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";

//import { Apps, CloudDownload, AccountCircle, EmojiObjects } from "@material-ui/icons";

import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MbuziLogo from "../../Assets/logo";
import { Grid } from "@material-ui/core";
import {
  CheeseIcon,
  ChickenIcon,
  CowIcon,
  GoatIcon,
  RabbitIcon,
  SheepIcon,
  SpiceIcon,
} from "../../Assets/icons/menuIcons";
import CustomDropdown from "./CustomDropdown";
//const CustomDropdown = lazy(() => import("./CustomDropdown"));
const ShopBar = lazy(() => import("./ShopBar"));
const MenuExpand = lazy(() => import("./MenuExpand"));

const ListButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    fontFamily: "Ubuntu",
    fontWeight: 400,
    textTransform: "none",
    fontSize: 16,
    marginLeft: " 0px !important",
    "&:hover": {
      color: "#FBEB00 !important",
    },
    "@media (max-width: 959.95px)": {
      marginLeft: 0,
    },
  },
}))(Button);
const LoginButton = withStyles((theme) => ({
  root: {
    color: "#212121",
    width: "200px",
    height: "35px !important",
    minHeight: "2.0rem !important",
    padding: "0px !important",
    backgroundColor: "#FBEB00",
    fontFamily: "Ubuntu",
    fontWeight: "500 !important",
    textTransform: "none",
    fontSize: "18px !important",
    marginLeft: " 15px !important",
    marginTop: " 20px !important",
    "&:hover": {
      color: "#FBEB00",
    },
    "@media (max-width: 959.95px)": {
      marginLeft: 0,
    },
  },
}))(Button);
const LoginTopButton = withStyles((theme) => ({
  root: {
    color: "#212121",
    width: "95px",
    height: "35px !important",
    minHeight: "2.0rem !important",
    padding: "0px !important",
    backgroundColor: "#FBEB00",
    fontFamily: "Ubuntu",
    fontWeight: "500 !important",
    textTransform: "none",
    fontSize: "18px !important",
    marginLeft: " 15px !important",
    "&:hover": {
      color: "#FBEB00",
    },
    "@media (max-width: 959.95px)": {
      marginLeft: 0,
    },
  },
}))(Button);

let cart = "";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  render() {
    const { classes, color, leftLinks, fixed, absolute, shopBar, shop } =
      this.props;

    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed,
    });
    const appBarClasses1 = classNames({
      [classes.appBar1]: true,
      [classes["white"]]: "white",
      [classes.absolute]: absolute,
      [classes.fixed]: fixed,
    });

    let brandComponent = "";
    let brandLink = "/";
    if (
      this.props.user.role === "User" ||
      this.props.user.role === "Admin" ||
      this.props.user.role === "Inner"
    ) {
      brandLink = "/";
    } else if (this.props.user.role === "Farmer") {
      brandLink = "/farmerpage";
    } else if (this.props.user.role === "Driver") {
      brandLink = "/driverpage";
    }
    brandComponent = (
      <Link to={brandLink} style={{ textDecoration: "none" }}>
        <Grid
          container
          direction="row"
          justifycontent="center"
          alignitems="center"
        >
          <MbuziLogo className={classes.logo} />
          <Button className={classes.title}>mbuzi24</Button>
        </Grid>
      </Link>
    );
    let shopBarComponent = "";
    if (shopBar) {
      shopBarComponent = (
        <AppBar className={appBarClasses1}>
          <ShopBar shop={shop} />
        </AppBar>
      );
    }
    if (this.props.cart.items.length > 0) {
      cart = (
        <Link to="/checkout2" style={{ textDecoration: "none" }}>
          <IconButton aria-label="cart">
            <Badge
              badgeContent={this.props.cart.items.length}
              color="secondary"
            >
              <ShoppingCartIcon style={{ color: "#FFFFFF" }} />
            </Badge>
          </IconButton>
        </Link>
      );
    } else {
      cart = "";
    }

    let auth = "";
    let auth1 = "";
    let auth2 = "";
    let dokList = [];
    let dokList1 = [];
    let adminLinks = [];
    let adminLinks1 = [];
    let innerLinks = [];
    let innerLinks1 = [];

    if (!this.props.isAuth || this.props.authInProcess) {
      auth = (
        <Link to="/signin" style={{ textDecoration: "none", color: "inherit" }}>
          <LoginTopButton className={classes.navLink}>Login</LoginTopButton>
        </Link>
      );
      auth2 = (
        <Link to="/signin" style={{ textDecoration: "none", color: "inherit" }}>
          <LoginButton className={classes.navLink}>LogIn</LoginButton>
        </Link>
      );
    } else {
      if (
        this.props.user.role === "Inner" ||
        this.props.user.role === "Admin"
      ) {
        if (this.props.user.role === "Admin") {
          adminLinks = [
            <Link to="/allusers" className={classes.dropdownLink}>
              All users
            </Link>,
            <Link to="/butchers" className={classes.dropdownLink}>
              Butchers
            </Link>,
            <Link to="/pricing" className={classes.dropdownLink}>
              Pricing
            </Link>,
            <Link to="/archorders" className={classes.dropdownLink}>
              Archieved Orders
            </Link>,
          ];
          adminLinks1 = [
            { link: "/allusers", text: "All users" },
            { link: "/butchers", text: "Butchers" },
            { link: "/archorders", text: "Archieved Orders" },
          ];
        }
        innerLinks = [
          <Link to="/allorders" className={classes.dropdownLink}>
            All orders
          </Link>,
          <Link to="/inventory" className={classes.dropdownLink}>
            Inventory
          </Link>,
          <Link to="/productionorders" className={classes.dropdownLink}>
            Production orders
          </Link>,
        ];
        innerLinks1 = [
          { link: "/allorders", text: "All orders" },
          { link: "/inventory", text: "Inventory" },
          { link: "/productionorders", text: "Production orders" },
        ];
      }
      dokList = [
        <Link to="/logout" className={classes.dropdownLink}>
          Log Out
        </Link>,
        <Link to="/user" className={classes.dropdownLink}>
          Profile
        </Link>,
      ]
        .concat(innerLinks)
        .concat(adminLinks);
      dokList1 = [
        { link: "/logout", text: "Log Out" },
        { link: "/user", text: "Profile" },
      ]
        .concat(innerLinks1)
        .concat(adminLinks1);
      auth = (
        <CustomDropdown
          buttonText={` ${this.props.user.name} `}
          buttonProps={{
            className: classes.navLink,
            variant: "text",
          }}
          dropdownList={dokList}
        />
      );
      auth1 = (
        <MenuExpand
          menuText={` ${this.props.user.name} `}
          menuList={dokList1}
        />
      );
    }
    let menuList = (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Link
            to="/aboutus"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ListButton className={classes.navLink}>About Us</ListButton>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to="/contact"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ListButton className={classes.navLink}>Contact</ListButton>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to="/newsupplier"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ListButton className={classes.navLink}>For Suppliers</ListButton>
          </Link>
        </ListItem>
        {auth1}
        <ListItem className={classes.listItem}>
          <div className={classes.drawerMenuGroup}>Products</div>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/choosemeat" className={classes.dropdownLink}>
            <ListButton className={classes.navLink}>
              <div className={classes.productIcon}>
                <GoatIcon height={22} className={classes.productIcon} />
              </div>
              <div className={classes.productTextInMenu}>Goat meat</div>
            </ListButton>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/choosemutton" className={classes.dropdownLink}>
            <ListButton className={classes.navLink}>
              <div className={classes.productIcon}>
                <SheepIcon height={22} className={classes.productIcon} />
              </div>
              <div className={classes.productTextInMenu}>Lamb/Mutton</div>
            </ListButton>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/choosebeef" className={classes.dropdownLink}>
            <ListButton className={classes.navLink}>
              <div className={classes.productIcon}>
                <CowIcon height={18} className={classes.productIcon} />
              </div>
              <div className={classes.productTextInMenu}>Beef</div>
            </ListButton>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/choosechicken" className={classes.dropdownLink}>
            <ListButton className={classes.navLink}>
              <div className={classes.productIcon}>
                <ChickenIcon height={25} className={classes.productIcon} />
              </div>
              <div className={classes.productTextInMenu}>Poultry & Eggs</div>
            </ListButton>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/chooserabbit" className={classes.dropdownLink}>
            <ListButton className={classes.navLink}>
              <div className={classes.productIcon}>
                <RabbitIcon height={19} className={classes.productIcon} />
              </div>
              <div className={classes.productTextInMenu}>Rabbit</div>
            </ListButton>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/choosecheese" className={classes.dropdownLink}>
            <ListButton className={classes.navLink}>
              <div className={classes.productIcon}>
                <CheeseIcon height={19} className={classes.productIcon} />
              </div>
              <div className={classes.productTextInMenu}>Cheese</div>
            </ListButton>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/choosespice" className={classes.dropdownLink}>
            <ListButton className={classes.navLink}>
              <div className={classes.productIcon}>
                <SpiceIcon height={20} className={classes.productIcon} />
              </div>
              <div className={classes.productTextInMenu}>Herbs & Sauces</div>
            </ListButton>
          </Link>
        </ListItem>
        {auth2}
      </List>
    );

    const headerLinks = (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Link
            to="/aboutus"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ListButton className={classes.navLink}>About Us</ListButton>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to="/contact"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ListButton className={classes.navLink}>Contact</ListButton>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to="/newsupplier"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ListButton className={classes.navLink}>For Suppliers</ListButton>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>{auth}</ListItem>
      </List>
    );

    return (
      <div>
        <AppBar className={appBarClasses}>
          <Toolbar className={classes.container}>
            {leftLinks !== undefined ? brandComponent : null}
            <div className={classes.flex}>
              {leftLinks !== undefined ? (
                <Hidden mdDown implementation="css">
                  {leftLinks}
                </Hidden>
              ) : (
                brandComponent
              )}
            </div>
            <Hidden mdDown implementation="css">
              <div style={{ width: "450px" }}>
                {shopBar ? (
                  <ShopBar shop={shop} style={{ width: "400px" }} />
                ) : (
                  ""
                )}
              </div>
            </Hidden>
            {cart}
            <Hidden mdDown implementation="css">
              {headerLinks}
            </Hidden>
            <Hidden lgUp>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerToggle}
              >
                <Menu />
              </IconButton>
            </Hidden>
          </Toolbar>
          <Hidden lgUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={this.state.mobileOpen}
              classes={{
                paper: classes.drawerPaper,
              }}
              onClose={this.handleDrawerToggle}
            >
              <div className={classes.drawerHeader}>Information</div>
              <div className={classes.appResponsive}>
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeIcon}
                >
                  {<CloseIcon />}
                </IconButton>
                {leftLinks}
                {menuList}
              </div>
            </Drawer>
          </Hidden>
        </AppBar>
        <Hidden lgUp implementation="css">
          {shopBarComponent}
        </Hidden>
      </div>
    );
  }
}
Navbar.defaultProp = {
  color: "dark",
};
const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAuth,
    user: state.auth.userInfo,
    authInProcess: state.auth.authInProcess,
    cart: state.reducer.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(withStyles(navbarStyle)(Navbar));
