import React, { lazy, useEffect, useState } from "react";
import { useSelector} from "react-redux";
import Navbar from "../Navbar/Navbar";
import landingPageStyle from "./landingPageStyle.jsx";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";

const HomeContent = lazy(() => import("./Components/HomeContent"));
const useStyles = makeStyles(landingPageStyle);

export default function Home() {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const userUID = useSelector((state) => state.auth.userUID);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const order = useSelector((state) => state.reducer.order);
  const [linkname, setLinkname] = useState(null);
  const authInProcess = useSelector((state) => state.auth.authInProcess);
  //const loading = useSelector((state) => state.reducer.loading);
  const classes = useStyles();

  useEffect(() => {
    //console.log(' effectis');
    if (userUID && !authInProcess) {
      if (order) {
        if (order.status==="paid" && order.paymentFinished===false) {
          setLinkname("copayment")
        } else {
          setLinkname("coopenorder")                
        }
      }
     /*  dispatch(actionCreators.changeLoading(true));
      db.collection("userOrders")
        .doc(userUID)
        .get()
        .then((doc) => {
          if (doc.exists) {
            db.collection("orders")
              .doc(doc.data().order)
              .get()
              .then((order) => {
                dispatch(actionCreators.newOrder({
                  ...order.data(),
                  id: doc.data().order,
                }));
                if (order.data().status==="paid" && order.data().paymentFinished===false) {
                  setLinkname("copayment")
                } else {
                  setLinkname("coopenorder")                
                }
                dispatch(actionCreators.changeLoading(false));
                //console.log('sain orderi')
              })
              .catch((error) => {
                console.log("Error getting order:", error);
              });
          } else {
            dispatch(actionCreators.newOrder(null))  
            dispatch(actionCreators.changeLoading(false));
          }
        })
        .catch((error) => {
          console.log("Error getting prices:", error);
          
        }); */

    }
    //console.log("UE: auth i process :", authInProcess )
    //console.log("UE: isauth :", isAuth )
    //console.log("UE: time :", new Date() )
    //dispatch(actionCreators.changeLoading(false));
       
  }, [authInProcess, order, userUID]);

  let pageContent = "";
  
  //console.log("B: auth i process :", authInProcess )
  //console.log("B: isauth :", isAuth )
  //console.log("B: time :", new Date() )
  if (isAuth && !authInProcess) {
    //console.log('render,oli auth');
    if (userInfo) {
      //console.log('render,oli role');
      if (userInfo.role === "Farmer") {
        return <Redirect to="/farmerpage" />;
      } else if (userInfo.role === "Driver") {
        return <Redirect to="/driverpage" />;
      } else {
        pageContent = (
          <HomeContent
            linkname={linkname} 
            headline="Farm Fresh Goat, Beef and Chicken"
            text1="Order 24/7 and schedule your"
            text2="convenient delivery time."
          />
        );
      }
      //console.log('render, lahen Redirecti', redirectTo);
    }
  } else {
    //console.log('redirect NO', this.state)
    pageContent = (
      <HomeContent
        linkname={linkname} 
        headline="Farm Fresh Goat, Beef and Chicken"
        text1="Order 24/7 and schedule your"
        text2="convenient delivery time."
      />
    );
  }

  return (
    <div className={classes.home}>
    <div className={classes.backgroundK}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Mbuzi24 - online butchery in Nairobi. Premium Beef, Goat and Chicken delivery, whole goats" />
        <meta name="keywords" content="mbuzi, goat, beef, chicken, meat, butchery, premium cuts" />
        <title>Online chicken, beef and goat meat delivery, fresh meat, live goat, Nairobi, Mbuzi</title>
        
      </Helmet>
      <Navbar color="transparent" brand="Mbuzi24" fixed />
      <div style={{ height: "50px" }}></div>
      {pageContent}
    </div>
    </div>
  );
}
