import reducer from './reducer';
import createDataReducer from './createDataReducer';
import authReducer from './authReducer';
import navReducer from './navReducer';
import { combineReducers } from 'redux'



const rootReducer = combineReducers({
  auth: authReducer,
  createData: createDataReducer,
  reducer: reducer,
  nav: navReducer
});
 
export default rootReducer;