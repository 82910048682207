import {
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  infoBoxShadow,
  successColor,
  successBoxShadow,
  warningColor,
  warningBoxShadow,
  dangerColor,
  dangerBoxShadow,
  roseColor,
  tooltip,
  roseBoxShadow,
} from "../../Style/baseStyle";

const navbarStyle = (theme) => ({
  appBar: {
    display: "flex",
    border: "0",
    borderRadius: "1px",
    padding: "0.225rem 0",
    marginBottom: "20px",
    color: "#555",
    height: "58px",
    width: "100%",
    backgroundColor: "#fff",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative",
    zIndex: "unset",
  },
  appBar1: {
    display: "flex",
    top: "57px",
    border: "0",
    borderRadius: "1px",
    padding: "0px",
    marginBottom: "20px",
    color: "#555",
    width: "100%",
    backgroundColor: "#FFFFFF",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative",
    zIndex: "unset",
  },
  absolute: {
    position: "absolute",
    zIndex: "1100",
  },
  fixed: {
    position: "fixed",
    zIndex: "1100",
  },
  container: {
    minHeight: "50px",
    paddingLeft: "0.8rem",
    flex: "1",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
  },
  flex: {
    flex: 1,
  },
  logo: {
    marginLeft: "5px",

  },
  title: {
    fontFamily: "Ubuntu",
    fontWeight: 500,
    lineHeight: "30px",
    fontSize: "23px",
    borderRadius: "3px",
    textTransform: "none",
    color: "white",
    padding: "8px 6px",
    "&:hover,&:focus": {
      color: "#FBEB00",
      background: "transparent",
    },
  },
  appResponsive: {
    margin: "0px 10px",
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)",
  },
  info: {
    backgroundColor: infoColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)",
  },
  success: {
    backgroundColor: successColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)",
  },
  warning: {
    backgroundColor: warningColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)",
  },
  danger: {
    backgroundColor: dangerColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)",
  },
  rose: {
    backgroundColor: roseColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)",
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    paddingTop: "25px",
    color: "#FFFFFF",
  },
  dark: {
    color: "#FFFFFF",
    backgroundColor: "#212121 !important",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)",
  },
  white: {
    border: "0",
    maxHeight: "40px",
    marginBottom: "20px",
    color: "#555",
    backgroundColor: "#fff !important",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
  },
  drawerPaper: {
    background: "#212121",
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: "260px",
    boxShadow:
      "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",

    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    marginLeft: "40px",
    paddingRight: "0px",
    paddingLeft: "0",
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
  },

  navLink: {
    color: "FFFFFF",
    minHeight: "2.3rem",
    position: "relative",
    padding: "0.3375rem",
    paddingLeft: "0.9375rem",
    fontWeight: "400",
    fontSize: "16px",
    textTransform: "none",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)",
    },
  },
  drawerHeader: {
    paddingTop: "23px",
    paddingLeft: "25px",
    paddingBottom: "0px",
    paddingRight: "15px",
    width: "100%",
    display: "flex",
    fontFamily: "League Spartan",
    fontWeight: 700,
    fontSize: "22px",
    color: "#FBEB00",
  },
  drawerMenuGroup: {
    marginLeft: "15px",
    marginTop: "10px !important",
    marginBottom: "10px !important",
    padding: "0",
    fontFamily: "League Spartan",
    fontWeight: 700,
    fontSize: "22px",
    color: "#FBEB00",
  },
  closeIcon: {
    position: "absolute",
    top: 5,
    right: 5,
    justifyContent: "right",
    alignItems: "right",
    paddingTop: "15px",
    paddingLeft: "0px",
    paddingBottom: "0px",
    paddingRight: "15px",
    color: "#FFFFFF",
  },
  icons: {
    width: "15px",
    height: "15px",
    marginRight: "3px",
  },
  productIcon: {
    width: "35px",
    display: "flex",
    justifyContent: "flex-start ",
    alignItems: "center",


  },
  productTextInMenu: {
    marginLeft: "5px",
  },
  dropdownLink: {
    "&,&:hover,&:focus": {
      color: "inherit",
      textDecoration: "none",
      display: "block",
    },
  },
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "FFFFFF",
  },
  listItem: {
    float: "left",
    color: "FFFFFF",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  listItemText: {
    padding: "0 !important",
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px",
  },
  caret: {
    transition: "all 150ms ease-in",
    display: "inline-block",
    width: "0",
    height: "0",
    marginLeft: "4px",
    verticalAlign: "middle",
    borderTop: "4px solid",
    borderRight: "4px solid transparent",
    borderLeft: "4px solid transparent",
  },
  caretActive: {
    transform: "rotate(180deg)",
  },
  caretRTL: {
    marginRight: "4px",
  },

  dropdownItem: {
    ...defaultFont,
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    position: "relative",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    height: "fit-content",
    color: "#333",
    whiteSpace: "nowrap",
  },
  dropdownItemRTL: {
    textAlign: "right",
  },

  blackHover: {
    "&:hover": {
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4)",
      backgroundColor: "#212121",
      color: "#fff",
    },
  },
  primaryHover: {
    "&:hover": {
      backgroundColor: primaryColor,
      color: "#FFFFFF",
      ...primaryBoxShadow,
    },
  },
  infoHover: {
    "&:hover": {
      backgroundColor: infoColor,
      color: "#FFFFFF",
      ...infoBoxShadow,
    },
  },
  successHover: {
    "&:hover": {
      backgroundColor: successColor,
      color: "#FFFFFF",
      ...successBoxShadow,
    },
  },
  warningHover: {
    "&:hover": {
      backgroundColor: warningColor,
      color: "#FFFFFF",
      ...warningBoxShadow,
    },
  },
  dangerHover: {
    "&:hover": {
      backgroundColor: dangerColor,
      color: "#FFFFFF",
      ...dangerBoxShadow,
    },
  },
  roseHover: {
    "&:hover": {
      backgroundColor: roseColor,
      color: "#FFFFFF",
      ...roseBoxShadow,
    },
  },
  noLiPadding: {
    padding: "0",
  },
  dropdownDividerItem: {
    margin: "5px 0",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    height: "1px",
    overflow: "hidden",
  },
  buttonIcon: {
    width: "20px",
    height: "20px",
  },
  popperResponsive: {
    zIndex: "1200",
    [theme.breakpoints.down("sm")]: {
      zIndex: "1640",
      position: "static",
      float: "none",
      width: "auto",
      marginTop: "0",
      backgroundColor: "transparent",
      border: "0",
      boxShadow: "none",
      color: "black",
    },
  },
  popperClose: {
    pointerEvents: "none",
  },

  dropdown: {
    borderRadius: "3px",
    border: "0",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
    top: "100%",
    zIndex: "1000",
    minWidth: "160px",
    padding: "5px 0",
    margin: "2px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
  },

  menuList: {
    padding: "5px",
  },
  dropdownHeader: {
    display: "block",
    padding: "0.1875rem 1.25rem",
    fontSize: "0.75rem",
    lineHeight: "1.428571",
    color: "#777",
    whiteSpace: "nowrap",
    fontWeight: "inherit",
    marginTop: "10px",
    "&:hover,&:focus": {
      backgroundColor: "transparent",
      cursor: "auto",
    },
  },
});

export default navbarStyle;
