const initialState = {
  pageError: false,
  prevPage: null,
};

const navReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_STATE":
      return {
        ...initialState,
      };
    case "PREVPAGE":
      return {
        ...state,
        prevPage: action.prevPage,
      };
    default:
      return state;
  }
};
export default navReducer;
