

export const authCheck = (isAuth, userUID) => {
   
    return {
        type: "XAUTH_CHECK",
        isAuth: isAuth,
        userUID: userUID
    };
}; 
export const userCheck = (userInfo) => {
   
    return {
        type: "XUSER_CHECK",
        userInfo: userInfo
    };
};

export const logout = () => {
    return {
        type: "XAUTH_LOGOUT"
    };
};

export const authInProcess = (authInProcess) => {
    return {
        type: "XAUTH_AUTHINPROCESS",
        authInProcess: authInProcess
    };
};

export const userInfoChange = (userInfo) => {
	return {
		type: 'STORE_USERINFO',
		userInfo: userInfo
	};
};

