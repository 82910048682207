

export const chooseMbuzi = (mbuzi) => {
	return {
		type: 'CHOOSE_MBUZI',
		mbuzi: mbuzi
	};
};
export const newOrder = (order) => {
	return {
		type: 'NEW_ORDER',
		order: order
	};
};
export const newAdminOrder = (order) => {
	return {
		type: 'NEW_ADMINORDER',
		order: order
	};
};

export const newDeliveryTime = (deliveryTime) => {
	return {
		type: 'NEW_DELIVERYTIME',
		deliveryTime: deliveryTime
	};
};

export const ordersToState = (orders) => {
	return {
		type: 'ORDERS_TO_STATE',
		orders: orders
	};
};
export const filteredOrdersToState = (orders) => {
	return {
		type: 'FILTEREDORDERS_TO_STATE',
		orders: orders
	};
};
export const usersToState = (users) => {
	return {
		type: 'USERS_TO_STATE',
		users: users
	};
};
export const addressToState = (address) => {
	return {
		type: 'ADDRESS_TO_STATE',
		address: address
	};
};
export const logoutData = () => {
	return {
		type: 'LOGOUT_DATA',
	};
};
export const farmersToState = (farmers) => {
	return {
		type: 'FARMERS_TO_STATE',
		farmers: farmers
	};
};
export const driversToState = (drivers) => {
	return {
		type: 'DRIVERS_TO_STATE',
		drivers: drivers
	};
};
export const pricesToState = (prices) => {
	return {
		type: 'PRICES_TO_STATE',
		prices: prices
	};
};
export const farmerPagesToState = (farmerPages) => {
	return {
		type: 'FARMERPAGES_TO_STATE',
		farmerPages: farmerPages
	};
};

export const cartUpdate = (cart) => {
	return {
		type: 'CART_TO_STATE',
		cart: cart
	};
};  
export const couponUpdate = (coupon) => {
	return {
		type: 'COUPON_TO_STATE',
		coupon: coupon
	};
}; 
export const changeLoading = (loading) => {
	return {
		type: "CHANGE_LOADING",
		loading: loading
	};
};
export const formParamsToState = (params) => {
	return {
		type: "PARAMS_TO_STATE",
		params: params
	};
};
export const paymentToState = (payment) => {
	return {
		type: "PAYMENT_TO_STATE",
		payment: payment
	};
};



