import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import 'typeface-roboto';
import rootReducer from './Store/reducers/rootReducer';
import Firebase, { FirebaseContext} from './firebase';

import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-N5K736F'
}

TagManager.initialize(tagManagerArgs)

const app = (
  <FirebaseContext.Provider value={new Firebase()}>
      <App />       
  </FirebaseContext.Provider>
);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(
applyMiddleware(thunk)
));

ReactDOM.render(<Provider store={store}>{app}</Provider>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
