import {
  backgroundP, logo, logoArea, logoSmall, logoAreaSmall, tag, tagArea, smallButton,
  smallBlackButton,
} from '../../Style/baseStyle';
const landingPageStyle = {
  backgroundP, logo, logoArea, logoSmall, logoAreaSmall, tag, tagArea, smallButton,
  smallBlackButton,
  mainTextDivider: {
    marginTop: "20px"
  },
  container: {
    "@media (min-width: 200px)": {
      marginTop: "0px",
      marginLeft: "0px", 
      marginRight: "0px",
    },
    "@media (min-width: 576px)": {
      marginTop: "30px",
      marginLeft: "0px",
      marginRight: "0px",
      padding: "10px 0 10px 0",
    },
    "@media (min-width: 768px)": {
      marginTop: "60px",
      marginLeft: "0px",
      marginRight: "0px",
      padding: "10px 0 10px 0",
    },
    "@media (min-width: 992px)": {
      marginTop: "100px",
      marginLeft: "0px",
      marginRight: "0px",
      padding: "10px 0 10px 0",
    },
    color: "#FFFFFF",
    textDecoration: "none",
    alignItems: "flex-end"
  },

  textArea: {
    marginLeft: "10px",
    marginRight: "10px"
  },


  section: {
    background: 'linear-gradient(to left, #7b4397, #dc2430)',
    height: "auto",
    maxHeight: "1000px",
    overflow: "hidden",
    position: "relative",
    width: "100%"
  },


  title: {

    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",

  },
  homepageh3: {
    color: "#FFFFFF",
  },
  homepagetextarea: {
    textDecoration: "none",
    position: "relative",
    alignItems: "center",
    marginTop: '40px',
    padding: " 0 0 0 10%",
    fontSize: "10vw",
    "@media (min-width: 200px)": {
      padding: " 0 10% 0 10%"
    },
    "@media (min-width: 768px)": {
      padding: " 0 0 0 10%"
    },
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  mainTextArea: {
    minHeight: "30vh",
    color: "#1C1E1E",
    background: "#FFFFFF",
    border: 0,
    borderRadius: "36px 36px 0px 36px",
    fontWeight: "400",
    fontSize: "16px",
    letterSpacing: "0px",
    padding: "10px",
    paddingTop: '15px',
    paddingBottom: '15px',
    opacity: "71%",

  },

  mainButtonArea: {
    margin: '20px 10px 10px 10px',
  },

  imgLeft: {
    "@media (min-width: 200px)": {
      maxWidth: "70%"
    },
    "@media (min-width: 576px)": {
      maxWidth: "70%"
    },
    "@media (min-width: 768px)": {
      maxWidth: "70%",

    },
    "@media (min-width: 992px)": {
      maxWidth: "100%",
      marginLeft: "-30%"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "110%",
      marginLeft: "-30%"
    },

  },
  imgFluid: {
    maxWidth: "100%",
    height: "auto",
    margin: "50% 0 0 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  formField: {
    padding: '10px'
  },
  contactIcons: {
    height: '30px',
    marginLeft: '10px'
  },
  fbIcon: {
    height: '40px',
    width: '40px',
    marginLeft: '0px'
  },
  contactDivider: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  headerImage: {
    width: "100%",
    height: "70%"
  },
  ///////////uued
  
  backgroundK: {
    textDecoration: "none",
    position: "relative",
    minHeight: '100vh',
    margin: 0,
    padding: 0,
    border: 0,
    alignItems: "center",
    background: `#FCEE23`,
  },
  backgroundW: {
    width: '100%',
    maxWidth: '100%',
    textDecoration: "none",
    position: "relative",
    minHeight: '100vh',
    margin: 0,
    padding: 0,
    border: 0,
    alignItems: "center",
    background: `#FFFFFF`,
  },
  pageContentArea: {
    fontFamily: 'Ubuntu',
    color: "#000000",
    fontWeight: 250,
    fontSize: "18px",
    letterSpacing: "0px",
    margin: '5px',
    marginTop: '0px',
    "@media (max-width: 600px)": {
      marginTop: "80px",
    },
  },
  landingPage: {
    fontFamily: 'Ubuntu',
    color: "#000000",
    fontWeight: 250,
    fontSize: "14px",
    letterSpacing: "0px",
    margin: '20px',
    marginTop: '80px',
    "@media (max-width: 600px)": {
      marginTop: "70px",
    },
  },
  smallText: {
    textAlign: "left",
    marginTop: "0px",
    color: "#000000",
    fontWeight: 400,
    fontSize: "16px",
    "@media (max-width: 600px)": {
      fontSize: "12px",
      marginTop: "16px",
      marginBottom: "2px",
    },
  },
  smallTextArea:{
    marginTop: "5px"
  },
  smallHeadline: {
    padding: "8px 24px",
    textAlign: "left",
    color: "#000000",
    fontWeight: 500,
    fontSize: "32px",
    "@media (max-width: 600px)": {
      fontSize: "16px",
    },
  },
  smallCaption: {
    padding: "8px 24px",
    textAlign: "left",
    color: "#000000",
    fontWeight: 500,
    fontSize: "24px",
    "@media (max-width: 600px)": {
      fontSize: "14px",
    },
  },
  paragraph: {
    marginTop: "5px",
    marginBottom: "5px"
  },

  headline: {
    textAlign: "center",
    marginTop: "0px",
    marginBottom: "10px",
    color: "#000000",
    fontWeight: 500,
    fontSize: "50px",
    "@media (max-width: 600px)": {
      fontSize: "40px",
      marginTop: "16px",
      marginBottom: "2px",
    },
  },
  headline1: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    fontWeight: 400,
    fontSize: "24px",
  },
  headline2: {
    textAlign: "left",
    marginTop: "20px",
    marginBottom: "10px",
    fontWeight: 400,
    fontSize: "20px",
  },

  errorText:{
    fontWeight: 500,
    fontSize: "16px",
    textAlign: "center",
    color: "#f55d42",
    textTransform: 'uppercase'
  },

  bodyTextArea: {
    marginTop: "100px",
    marginLeft: "20px",
    marginRight: "20px",
    "@media (max-width: 600px)": {
      marginTop: "40px",
      marginLeft: "10px",
      marginRight: "10px",
    },
  },
  backButton: {
    width: '150px',
    height: '40px',
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    fontSize: "16px",
    marginTop: '25px',
    marginBottom: '60px'
  },
  socialMediaButton: {
    color: '#000000',
    padding: '5px'
  },
  driverTable: {
    padding: "5px"
  },
  serviceAreaHeadline: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    fontFamily: 'Ubuntu',
    color: "#000000",
    fontWeight: 400,
    fontSize: "42px",
    letterSpacing: "0px",
    "@media (max-width: 600px)": {
      marginTop: "10px",
      fontSize: "28px",
    },
  },
  serviceAreaText: {
    marginTop: "20px",
    fontFamily: 'Ubuntu',
    color: "#000000",
    fontWeight: 250,
    fontSize: "24px",
    letterSpacing: "0px",
    "@media (max-width: 600px)": {
      marginTop: "10px",
      fontSize: "18px",
    },
  },
  serviceArea:{
    marginTop: "30px",
    marginBottom: "30px",
    textAlign: "center",
    backgroundSize: "contain",
    backgroundPosition: "center center",
    minHeight: "300px",
    width: "100%",
    "@media (min-width: 500px)": { 
      minHeight: "500px",
    },
    "@media (min-width: 800px)": { 
      minHeight: "700px",
    },
    "@media (min-width: 1050px)": { 
      minHeight: "800px",
    },
    "@media (min-width: 1300px)": { 
      minHeight: "900px",
    },
  },
};


export default landingPageStyle;
