const initialState = {
  token: null,
  userUID: null,
  loginError: null,
  loading: false,
  authError: null,
  isAuth: false,
  userInfo: {},
  sessions: null,
  session: null,
  authInProcess: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "XAUTH_CHECK":
      return {
        ...state,
        isAuth: action.isAuth,
        userUID: action.userUID,
      };

    case "XUSER_CHECK":
      return {
        ...state,
        userInfo: action.userInfo,
      };

    case "XAUTH_LOGOUT":
      return {
        ...state,
        isAuth: false,
        token: null,
        userInfo: {},
      };

    case "XAUTH_AUTHINPROCESS":
      return {
        ...state,
        authInProcess: action.authInProcess,
      };

    case "STORE_USERINFO":
      return {
        ...state,
        userInfo: action.userInfo,
      };

    default:
      return state;
  }
};
export default authReducer;
