import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFirebase } from "../index";
import * as authActions from "../../Store/actions/authActions";
import * as actionCreators from "../../Store/actions/actions";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.props.onAuthChecking(false);
    }

    componentDidMount() {
      this.props.firebase.auth.onAuthStateChanged((user) => {
        this.props.onAuthInProcess(true);
        //console.log('wa miskit hakkab toimuma')
        if (user) {
          this.props.onAuthChecking(true, user.uid);
          const db = this.props.firebase.db;
          //console.log('uid on:',user.uid);
          db.collection("users")
            .doc(user.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                //console.log('user andmebaasist leitud',doc.data());
                this.props.onUserChecking(doc.data());
                this.props.firebase.logEvent("login");
                //console.log('wa toiimus login')
                db.collection("userOrders")
                  .doc(user.uid)
                  .get()
                  .then((userOrder) => {
                    if (userOrder.exists) {
                      db.collection("orders")
                        .doc(userOrder.data().order)
                        .get()
                        .then((order) => {
                          this.props.onNewOrder({
                            ...order.data(),
                            id: userOrder.data().order,
                          });
                          this.props.onAuthInProcess(false);
                          //console.log("sain orderi");
                        })
                        .catch((error) => {
                          console.log("Error getting order:", error);
                        });
                    } else {
                      this.props.onNewOrder(null);
                      this.props.onAuthInProcess(false);
                      //console.log("useril orderit polnud");
                    }
                  })
                  .catch((error) => {
                    console.log("Error getting prices:", error);
                  });
              } else {
                //console.log('No such uid!');
              }
            })
            .catch((error) => {
              console.log("Error getting uid document:", error);
            });

          //this.props.onAuthChecking(true, userInfo);
          // var displayName = user.displayName;
          // var email = user.email;
          // var emailVerified = user.emailVerified;
          // var photoURL = user.photoURL;
          // var uid = user.uid;
          // var providerData = user.providerData;
        } else {
          this.props.onAuthChecking(false);
          //console.log('wa kasutajat pole')
          this.props.onAuthInProcess(false);
        }
      });
    }
    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      onAuthChecking: (isAuth, userUID) =>
        dispatch(authActions.authCheck(isAuth, userUID)),
      onUserChecking: (userInfo) => dispatch(authActions.userCheck(userInfo)),
      onAuthInProcess: (authInProcess) =>
        dispatch(authActions.authInProcess(authInProcess)),
      onNewOrder: (order) => dispatch(actionCreators.newOrder(order)),
    };
  };
  return compose(
    withFirebase,
    connect(null, mapDispatchToProps)
  )(WithAuthentication);
};

export default withAuthentication;
